<template>
  <esmp-modal
    v-model="show"
    title="Продление делегирования"
    :width="800"
    class-name="modal-delegation-prolongation"
  >
    <esmp-select v-model="monthProlongation" placeholder="Период (в месяцах)">
      <esmp-select-option
        v-for="item in DELEGATION_PROLONGATION_LIST"
        :value="item"
        :key="item"
      >
        {{ item }}
      </esmp-select-option>
    </esmp-select>
    <template #footer>
      <esmp-button @click="prolongHandler" :disabled="!monthProlongation">
        Ок
      </esmp-button>
      <esmp-button @click="cancel">
        Отмена
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions } from 'vuex';

const DELEGATION_PROLONGATION_LIST = [3, 6, 9, 12];

export default {
  name: 'ModalDelegationProlongation',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    delegation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      monthProlongation: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('toggle-modal', { modalName: this.$options.name, modalState: newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    prolongHandler() {
      this.setLoading({ key: 'page', value: true });
      const delegation = { ...this.delegation };
      delegation.dateTo = dayjs(delegation.dateTo).add(this.monthProlongation, 'month');
      this.$API.delegation.updateDelegation(delegation.id, delegation)
        .then(() => {
          this.$emit('toggle-modal', { modalName: this.$options.name, modalState: false });
          this.$EsmpNotify.$show('Изменения делегирования успешно сохранены', 'success');
          if (this.$route.name !== 'Delegations') this.$router.push({ name: 'Delegations' });
        })
        .catch(() => {
          this.$EsmpNotify.$show('Ошибка при изменении делегирования', 'error');
        }).finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    cancel() {
      this.$emit('toggle-modal', { modalName: this.$options.name, modalState: false });
    },
  },
  created() {
    this.DELEGATION_PROLONGATION_LIST = Object.freeze(DELEGATION_PROLONGATION_LIST);
  },
};
</script>
